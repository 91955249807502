var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import range from "lodash/range";
import { queryWithoutPagination } from "../../utils/request";
export default Vue.extend({
    props: {
        currentPageIndex: { type: Number, required: true },
        totalItems: { type: Number, required: true },
        pageSize: { type: Number, required: true },
        label: { type: String, required: true },
    },
    computed: {
        previousPageTo: function () {
            if (this.currentPageIndex === 0) {
                return undefined;
            }
            return this.getPageTo(this.currentPageIndex);
        },
        nextPageTo: function () {
            if (this.currentPageIndex >= this.pages.length - 1) {
                return undefined;
            }
            return this.getPageTo(this.currentPageIndex + 2);
        },
        pages: function () {
            var _this = this;
            var pages = range(1, Math.ceil(this.totalItems / this.pageSize) + 1).map(function (page) { return _this.getPageTo(page); });
            if (pages.length > 1) {
                return pages;
            }
            return [];
        },
        itemNumbers: function () {
            var firstPageItemNumber = 1 + this.currentPageIndex * this.pageSize;
            var lastPageItemNumber = Math.min(this.totalItems, firstPageItemNumber + this.pageSize - 1);
            if (firstPageItemNumber === lastPageItemNumber) {
                return firstPageItemNumber.toString();
            }
            return "".concat(firstPageItemNumber, " - ").concat(lastPageItemNumber);
        },
    },
    methods: {
        getPageTo: function (pageNumber) {
            if (pageNumber === 1) {
                return { query: queryWithoutPagination(this.$route) };
            }
            return { query: __assign(__assign({}, this.$route.query), { page: pageNumber }) };
        },
    },
});
