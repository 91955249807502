import Vue from "vue";
import SearchFilters from "./SearchFilters.vue";
export default Vue.extend({
    components: {
        SearchFilters: SearchFilters,
    },
    props: {
        institutionOptions: {
            type: Array,
            required: true,
        },
    },
});
