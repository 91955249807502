var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead/dist/VueBootstrapTypeahead.common.js";
import gql from "graphql-tag";
import { queryWithoutPagination } from "../../../utils/request";
import institutionDisplayNameFragment from "../../../utils/institutionDisplayNameFragment.graphql";
import AsyncStatus from "../../../utils/async";
import debounce from "lodash/debounce";
var debouncedPerformSearch = debounce(function performSearch(query) {
    var _this = this;
    this.areMatchesDirty = false;
    this.searchStatus.run(this.$apollo
        .query({
        query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          query UniversityOrCityTypeaheadQuery($query: String!) {\n            searchInstitutionsAndCities(query: $query) {\n              cities {\n                name\n                slug\n                stateCode\n              }\n              institutions {\n                ...InstitutionDisplayName_institution\n                slug\n                citySlug\n              }\n              listings {\n                fullAddress\n                propertyName\n                slug\n                cityName\n              }\n            }\n          }\n          ", "\n        "], ["\n          query UniversityOrCityTypeaheadQuery($query: String!) {\n            searchInstitutionsAndCities(query: $query) {\n              cities {\n                name\n                slug\n                stateCode\n              }\n              institutions {\n                ...InstitutionDisplayName_institution\n                slug\n                citySlug\n              }\n              listings {\n                fullAddress\n                propertyName\n                slug\n                cityName\n              }\n            }\n          }\n          ", "\n        "])), institutionDisplayNameFragment),
        variables: { query: query },
    })
        .then(function (_a) {
        var _b = _a.data.searchInstitutionsAndCities, cities = _b.cities, institutions = _b.institutions, listings = _b.listings;
        // const MAX_RESULTS = 10;
        // const MAX_INDIVIDUAL = MAX_RESULTS / 2;
        // let numCities = cities.length;
        // if (numCities > MAX_INDIVIDUAL) {
        //   numCities = Math.max(
        //     MAX_INDIVIDUAL,
        //     MAX_RESULTS - institutions.length
        //   );
        // }
        // const numInstitutions = Math.min(
        //   institutions.length,
        //   MAX_RESULTS - numCities
        // );
        var maxIndividualNum = 4;
        _this.matches = __spreadArray(__spreadArray(__spreadArray([], cities.slice(0, maxIndividualNum).map(function (city) { return (__assign({ type: "city", label: "".concat(city.name, ", ").concat(city.stateCode.toUpperCase()) }, city)); }), true), institutions.slice(0, maxIndividualNum).map(function (institution) { return (__assign({ type: "institution", label: "".concat(institution.name, " (").concat(institution.nickname, ") - ").concat(institution.campus) }, institution)); }), true), listings.slice(0, maxIndividualNum).map(function (listing) {
            var label = listing.fullAddress;
            if (listing.propertyName) {
                label = listing.propertyName + ", " + label;
            }
            return {
                type: "listing",
                label: label,
                cityName: listing.cityName,
                propertyName: listing.propertyName,
                slug: listing.slug,
            };
        }), true);
    }));
}, 400);
export default Vue.extend({
    components: {
        VueBootstrapTypeahead: VueBootstrapTypeahead,
    },
    data: function () {
        return {
            areMatchesDirty: false,
            minMatchingChars: 2,
            matches: [],
            selectedAddress: undefined,
            searchStatus: new AsyncStatus(),
            searchQuery: "",
        };
    },
    computed: {
        hasNoResults: function () {
            if (this.areMatchesDirty) {
                return false;
            }
            if (this.searchQuery.length < this.minMatchingChars) {
                return false;
            }
            return !this.searchStatus.isWorking && this.matches.length === 0;
        },
        firstCity: function () {
            if (!this.matches) {
                return undefined;
            }
            return this.matches.find(function (m) { return m.type === "city"; });
        },
        firstInstitution: function () {
            if (!this.matches) {
                return undefined;
            }
            return this.matches.find(function (m) { return m.type === "institution"; });
        },
        firstListing: function () {
            if (!this.matches) {
                return undefined;
            }
            return this.matches.find(function (m) { return m.type === "listing"; });
        },
    },
    methods: {
        onInputChange: function (text) {
            if (text.length === 0) {
                this.matches = [];
            }
            else {
                this.areMatchesDirty = true;
                this.performSearch(text);
            }
        },
        performSearch: function (query) {
            debouncedPerformSearch.call(this, query);
        },
        onSelect: function (match) {
            switch (match.type) {
                case "city":
                    this.$router.push({
                        path: "/accommodation/".concat(encodeURIComponent(match.slug)),
                        query: queryWithoutPagination(this.$route),
                        hash: this.$route.hash,
                    });
                    break;
                case "institution":
                    this.$router.push({
                        path: "/accommodation/".concat(encodeURIComponent(match.citySlug), "/").concat(encodeURIComponent(match.slug)),
                        query: queryWithoutPagination(this.$route),
                        hash: this.$route.hash,
                    });
                    break;
                case "listing":
                    this.$router.push({
                        path: "/accommodation/listing/".concat(encodeURIComponent(match.slug)),
                        query: queryWithoutPagination(this.$route),
                        hash: this.$route.hash,
                    });
                    break;
            }
            this.$emit("select");
            this.searchQuery = "";
        },
    },
});
var templateObject_1;
