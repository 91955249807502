var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from "moment";
export function getSingleQueryValue(route, name, defaultValue) {
    if (defaultValue === void 0) { defaultValue = ""; }
    var rawQueryValue = route.query[name];
    var rawValue;
    if (Array.isArray(rawQueryValue)) {
        rawValue = rawQueryValue[0];
    }
    else {
        rawValue = rawQueryValue;
    }
    if (!rawValue) {
        return defaultValue;
    }
    return rawValue;
}
export function getMultipleQueryValue(route, name) {
    var rawQueryValue = route.query[name];
    if (Array.isArray(rawQueryValue)) {
        return rawQueryValue.filter(function (v) { return !!v; });
    }
    if (rawQueryValue) {
        return [rawQueryValue];
    }
    return [];
}
export function getSingleIntQueryValue(route, name, defaultValue) {
    var value = parseInt(getSingleQueryValue(route, name), 10);
    if (isNaN(value)) {
        return defaultValue;
    }
    return value;
}
export function getSingleDateQueryValue(route, name) {
    var value = getSingleQueryValue(route, name);
    if (value === "") {
        return undefined;
    }
    return moment(value).toDate();
}
export function newSearchQuery(route, values) {
    var newQuery = __assign({}, route.query);
    Object.keys(values).forEach(function (key) {
        var value = values[key];
        if (value) {
            newQuery[key] = value;
        }
        else {
            delete newQuery[key];
        }
    });
    return newQuery;
}
export function queryWithoutPagination(_a) {
    var query = _a.query;
    var newQuery = __assign({}, query);
    delete newQuery.page;
    return newQuery;
}
