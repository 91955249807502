var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import getFormattedRentalPerWeek from "../../../../features/accommodation/getFormattedRentalPerWeek";
import propertyListingPriceFragment from "../../../../features/accommodation/propertyListingPriceFragment.graphql";
export default Vue.extend({
    props: {
        property: {
            type: Object,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
        isSelected: {
            type: Boolean,
            required: true,
        },
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment MapSearchItem_property on PropertyListing {\n        id\n        slug\n        streetAddress\n        mainThumbnailUrl\n        ...PropertyListingPrice_propertyListing\n      }\n      ", "\n    "], ["\n      fragment MapSearchItem_property on PropertyListing {\n        id\n        slug\n        streetAddress\n        mainThumbnailUrl\n        ...PropertyListingPrice_propertyListing\n      }\n      ", "\n    "])), propertyListingPriceFragment),
    },
    computed: {
        formattedRentalPerWeek: function () {
            return getFormattedRentalPerWeek(this.$t.bind(this), this.property);
        },
    },
});
var templateObject_1;
