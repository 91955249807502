var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import PropertyCard from "../../public/accommodation/PropertyCard.vue";
import gql from "graphql-tag";
import SaveButton from "./SaveButton.vue";
import numeral from "numeral";
export default Vue.extend({
    components: {
        PropertyCard: PropertyCard,
        SaveButton: SaveButton,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
        distanceToName: {
            type: String,
            required: true,
        },
        linkQuery: {
            type: Object,
            default: undefined,
        },
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment SearchPropertyCard_property on PropertyListing {\n        id\n        distanceTo(\n          city: $distanceToCitySlug\n          institution: $distanceToInstitutionSlug\n        )\n        ...PropertyCard_property\n        ...SaveButton_property\n      }\n      ", "\n      ", "\n    "], ["\n      fragment SearchPropertyCard_property on PropertyListing {\n        id\n        distanceTo(\n          city: $distanceToCitySlug\n          institution: $distanceToInstitutionSlug\n        )\n        ...PropertyCard_property\n        ...SaveButton_property\n      }\n      ", "\n      ", "\n    "])), PropertyCard.options.fragments.property, SaveButton.options.fragments.property),
    },
    computed: {
        formattedDistance: function () {
            return numeral(this.property.distanceTo / 1000).format("0,0.0");
        },
    },
});
var templateObject_1;
