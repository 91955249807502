var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import range from "lodash/range";
import { getMultipleQueryValue, getSingleDateQueryValue, getSingleQueryValue, newSearchQuery, } from "../../../utils/request";
import AsyncStatus from "../../../utils/async";
import { displayName } from "../../../utils/institution";
import { moneyFormat } from "../../../utils/filters";
import UniversityOrCityTypeahead from "./UniversityOrCityTypeahead.vue";
// TODO: Should get these from backend? e.g. round to nearest 1s or 5s or 10s etc
var currencyMultipliers = {
    AUD: 1,
    SGD: 1,
    CNY: 5,
    IDR: 10000,
};
export default Vue.extend({
    components: {
        UniversityOrCityTypeahead: UniversityOrCityTypeahead,
    },
    props: {
        institutionOptions: {
            type: Array,
            required: true,
        },
        isSearching: {
            type: Boolean,
            required: true,
        },
        openDatesUpwards: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            institution: this.$route.params.institutionSlug || "",
            propertyTypes: getMultipleQueryValue(this.$route, "propertyTypes"),
            roomTypes: getMultipleQueryValue(this.$route, "roomTypes"),
            priceFrom: getSingleQueryValue(this.$route, "priceFrom"),
            priceTo: getSingleQueryValue(this.$route, "priceTo"),
            moveInDate: getSingleDateQueryValue(this.$route, "moveInDate"),
            disabledMoveInDates: {
                to: moment(),
            },
        };
    },
    computed: __assign({ institutionSelectOptions: function () {
            return this.institutionOptions.map(function (institution) { return ({
                value: institution.slug,
                label: displayName(institution),
            }); });
        }, propertyTypeOptions: function () {
            return [
                {
                    label: this.$t("Student accommodation").toString(),
                    value: "s",
                    summary: this.$t("Shared living in a managed building with onsite teams and shared facilities"),
                },
                {
                    label: this.$t("Private housing").toString(),
                    value: "p",
                    summary: this.$t("Private or shared living in a house, apartment, hotel or homestay"),
                },
            ];
        }, roomTypeOptions: function () {
            return [
                {
                    label: this.$t("Shared room").toString(),
                    value: "s",
                    summary: this.$t("Share your room along with bathroom, kitchen & common spaces."),
                },
                {
                    label: this.$t("Private room").toString(),
                    value: "p",
                    summary: this.$t("Have your own room & study spaces. Share some common spaces like kitchen & living room."),
                },
                {
                    label: this.$t("Entire place").toString(),
                    value: "e",
                    summary: this.$t("Have entire place to yourself. Room, study space, bathroom, kitchen & living room etc."),
                },
            ];
        }, 
        // Maybe should pass status in instead?
        searchingStatus: function () {
            var status = new AsyncStatus();
            if (this.isSearching) {
                status.run(new Promise(function () { return undefined; })); // eslint-disable-line vue/no-async-in-computed-properties
                return status;
            }
            return status;
        }, priceRangeOptions: function () {
            var _this = this;
            return range(0, 501, 50)
                .map(function (price) { return price * currencyMultipliers[_this.currency]; })
                .map(function (price) { return ({
                value: price.toString(),
                label: moneyFormat({
                    currency: _this.currency,
                    amount: price.toString(),
                }),
            }); });
        }, priceFromOptions: function () {
            var _this = this;
            return this.priceRangeOptions.filter(function (_a) {
                var value = _a.value;
                return !_this.priceTo || parseInt(value) <= parseInt(_this.priceTo);
            });
        }, priceToOptions: function () {
            var _this = this;
            return this.priceRangeOptions.filter(function (_a) {
                var value = _a.value;
                return !_this.priceFrom || parseInt(value) >= parseInt(_this.priceFrom);
            });
        } }, mapState(["currency"])),
    watch: {
        institution: function () {
            this.onSearchSubmit();
        },
        propertyTypes: function () {
            this.onSearchSubmit();
        },
        roomTypes: function () {
            this.onSearchSubmit();
        },
        priceFrom: function () {
            this.onSearchSubmit();
        },
        priceTo: function () {
            this.onSearchSubmit();
        },
        moveInDate: function () {
            var _this = this;
            this.$nextTick(function () { return _this.onSearchSubmit(); });
        },
    },
    methods: {
        onSearchSubmit: function (event) {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            this.$emit("search");
            var location = {
                hash: this.$route.hash,
                query: newSearchQuery(this.$route, {
                    propertyTypes: this.propertyTypes,
                    roomTypes: this.roomTypes,
                    priceFrom: this.priceFrom,
                    priceTo: this.priceTo,
                    moveInDate: this.moveInDate
                        ? moment(this.moveInDate).format("YYYY-MM-DD")
                        : "",
                }),
            };
            if (this.institution) {
                location.path = "/accommodation/".concat(this.$route.params.citySlug, "/").concat(this.institution);
            }
            else {
                location.path = "/accommodation/".concat(this.$route.params.citySlug);
            }
            // Catch error for navigating to same url
            this.$router.push(location).catch(function (e) { return console.log(e); });
        },
    },
});
