var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { toMapboxLatLong } from "../../../../utils/mapbox";
import propertyListingPriceFragment from "../../../../features/accommodation/propertyListingPriceFragment.graphql";
import ListingPopupContent from "./ListingPopupContent.vue";
import { getPropertyListingPrice } from "../../../../features/accommodation";
import { moneyFormat } from "../../../../utils/filters";
export default Vue.extend({
    components: {
        ListingPopupContent: ListingPopupContent,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
        isSelected: {
            type: Boolean,
            required: true,
        },
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment ListingSearchMarker_property on PropertyListing {\n        id\n        latLong {\n          lat\n          long\n        }\n        ...PropertyListingPrice_propertyListing\n        ...ListingPopupContent_property\n      }\n      ", "\n      ", "\n    "], ["\n      fragment ListingSearchMarker_property on PropertyListing {\n        id\n        latLong {\n          lat\n          long\n        }\n        ...PropertyListingPrice_propertyListing\n        ...ListingPopupContent_property\n      }\n      ", "\n      ", "\n    "])), ListingPopupContent.options.fragments.property, propertyListingPriceFragment),
    },
    computed: {
        coordinates: function () {
            return toMapboxLatLong(this.property.latLong);
        },
        formattedPrice: function () {
            var price = getPropertyListingPrice(this.property);
            if (price === undefined) {
                return undefined;
            }
            return moneyFormat(price.price);
        },
    },
    methods: {
        hidePopup: function () {
            if (!this.$refs.popup || !this.$refs.marker) {
                return;
            }
            if (this.$refs.popup.open) {
                this.$refs.marker.togglePopup();
            }
        },
        showPopup: function () {
            if (!this.$refs.popup || !this.$refs.marker) {
                return;
            }
            if (!this.$refs.popup.open) {
                this.$refs.marker.togglePopup();
            }
        },
    },
});
var templateObject_1;
