var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import gql from "graphql-tag";
import SearchFilters from "../SearchFilters.vue";
import ListingSearchMarker from "./ListingSearchMarker.vue";
import { mapState } from "vuex";
import { toMapboxLatLong, } from "../../../../utils/mapbox";
import MapSearchItem from "./MapSearchItem.vue";
import find from "lodash/find";
import InstitutionMarker from "./InstitutionMarker.vue";
import institutionDisplayNameFragment from "../../../../utils/institutionDisplayNameFragment.graphql";
export default Vue.extend({
    components: {
        SearchFilters: SearchFilters,
        ListingSearchMarker: ListingSearchMarker,
        MapSearchItem: MapSearchItem,
        InstitutionMarker: InstitutionMarker,
    },
    data: function () {
        return {
            highlightedPropertyId: undefined,
            selectedPropertyId: undefined,
        };
    },
    apollo: {
        livePropertiesMapSearch: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query SearchMapQuery(\n          $city: String\n          $institution: String\n          $propertyTypes: [String!]\n          $roomTypes: [String!]\n          $priceFrom: Int\n          $priceTo: Int\n          $moveInDate: Date\n        ) {\n          livePropertiesMapSearch(\n            city: $city\n            institution: $institution\n            propertyTypes: $propertyTypes\n            roomTypes: $roomTypes\n            rentalPerWeekFrom: $priceFrom\n            rentalPerWeekTo: $priceTo\n            moveInDate: $moveInDate\n          ) {\n            id\n            latLong {\n              lat\n              long\n            }\n            ...ListingSearchMarker_property\n            ...MapSearchItem_property\n          }\n        }\n        ", "\n        ", "\n      "], ["\n        query SearchMapQuery(\n          $city: String\n          $institution: String\n          $propertyTypes: [String!]\n          $roomTypes: [String!]\n          $priceFrom: Int\n          $priceTo: Int\n          $moveInDate: Date\n        ) {\n          livePropertiesMapSearch(\n            city: $city\n            institution: $institution\n            propertyTypes: $propertyTypes\n            roomTypes: $roomTypes\n            rentalPerWeekFrom: $priceFrom\n            rentalPerWeekTo: $priceTo\n            moveInDate: $moveInDate\n          ) {\n            id\n            latLong {\n              lat\n              long\n            }\n            ...ListingSearchMarker_property\n            ...MapSearchItem_property\n          }\n        }\n        ", "\n        ", "\n      "])), ListingSearchMarker.options.fragments.property, MapSearchItem.options.fragments.property),
            variables: function () {
                var citySlug = this.$route.params.institutionSlug
                    ? undefined
                    : this.$route.params.citySlug;
                return {
                    city: citySlug,
                    institution: this.$route.params.institutionSlug,
                    propertyTypes: this.$route.query.propertyTypes,
                    roomTypes: this.$route.query.roomTypes,
                    priceFrom: this.$route.query.priceFrom,
                    priceTo: this.$route.query.priceTo,
                    moveInDate: this.$route.query.moveInDate,
                };
            },
        },
        cityBySlug: {
            query: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        query SearchMapCityQuery($slug: String!) {\n          cityBySlug(slug: $slug) {\n            slug\n            name\n            latLong {\n              lat\n              long\n            }\n            institutions {\n              slug\n              ...InstitutionDisplayName_institution\n            }\n          }\n        }\n        ", "\n      "], ["\n        query SearchMapCityQuery($slug: String!) {\n          cityBySlug(slug: $slug) {\n            slug\n            name\n            latLong {\n              lat\n              long\n            }\n            institutions {\n              slug\n              ...InstitutionDisplayName_institution\n            }\n          }\n        }\n        ", "\n      "])), institutionDisplayNameFragment),
            variables: function () {
                return {
                    slug: this.$route.params.citySlug,
                };
            },
        },
        institutionBySlug: {
            query: gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        query SearchMapInstitutionQuery($slug: String!) {\n          institutionBySlug(slug: $slug) {\n            slug\n            name\n            latLong {\n              lat\n              long\n            }\n            ...InstitutionMarker_institution\n          }\n        }\n        ", "\n      "], ["\n        query SearchMapInstitutionQuery($slug: String!) {\n          institutionBySlug(slug: $slug) {\n            slug\n            name\n            latLong {\n              lat\n              long\n            }\n            ...InstitutionMarker_institution\n          }\n        }\n        ", "\n      "])), InstitutionMarker.options.fragments.institution),
            variables: function () {
                return {
                    slug: this.$route.params.institutionSlug,
                };
            },
            skip: function () {
                return !this.$route.params.institutionSlug;
            },
        },
    },
    computed: __assign({ selectedInstitution: function () {
            if (!this.$route.params.institutionSlug) {
                return;
            }
            return this.institutionBySlug;
        }, properties: function () {
            return this.livePropertiesMapSearch || [];
        }, mapboxLatLong: function () {
            if (this.$route.params.institutionSlug && this.institutionBySlug) {
                return toMapboxLatLong(this.institutionBySlug.latLong);
            }
            if (this.cityBySlug) {
                return toMapboxLatLong(this.cityBySlug.latLong);
            }
            return undefined;
        }, institutionOptions: function () {
            if (this.cityBySlug) {
                return this.cityBySlug.institutions;
            }
            return [];
        }, mapboxMap: function () {
            return this.$refs.mglMap.map;
        } }, mapState(["mapboxAccessToken"])),
    methods: {
        onBackClick: function () {
            this.$router.push({
                query: this.$route.query,
                hash: "",
            });
        },
        getPopupMarkerByPropertyId: function (propertyId) {
            var refs = this.$refs.popupMarkers;
            if (!Array.isArray(refs)) {
                return;
            }
            return find(refs, function (ref) {
                return ref &&
                    ref.property.id === propertyId;
            });
        },
        onSearchItemSelect: function (property) {
            if (this.selectedPropertyId) {
                var oldPopup = this.getPopupMarkerByPropertyId(this.selectedPropertyId);
                if (oldPopup) {
                    oldPopup.hidePopup();
                }
            }
            var popup = this.getPopupMarkerByPropertyId(property.id);
            if (!popup) {
                return;
            }
            popup.showPopup();
            this.onMarkerSelect(property);
        },
        onMarkerSelect: function (property) {
            this.selectedPropertyId = property.id;
            if (!this.mapboxMap) {
                return;
            }
            this.mapboxMap.flyTo({
                center: toMapboxLatLong(property.latLong),
                zoom: 15,
            });
        },
        onMarkerDeselect: function (property) {
            if (this.selectedPropertyId === property.id) {
                this.selectedPropertyId = undefined;
            }
        },
        onMarkerHighlight: function (_a) {
            var id = _a.id;
            this.highlightedPropertyId = id;
        },
        onMarkerDehighlight: function (_a) {
            var id = _a.id;
            if (this.highlightedPropertyId === id) {
                this.highlightedPropertyId = undefined;
            }
        },
    },
});
var templateObject_1, templateObject_2, templateObject_3;
